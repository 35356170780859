<template>
  <div>
    <!-- 与操作按钮相分隔开的内容 -->
    <div>
      <!-- 基础信息 -->
      <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width: 100%;
        "
      >
        <div style="font-size: 20px; margin: 8px 0 20px 0">基础信息</div>
        <div class="prCon">
          <div class="childCon">
            <span class="childMt">客户姓名:</span>
            <span>{{ taskDetail.contacts }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">订单状态:</span>
            <span>{{ taskDetail.states }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">订单号:</span>
            <span>{{ taskDetail.order_id }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">店铺:</span>
            <span>{{ taskDetail.store_name }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">负责店长:</span>
            <span>{{ taskDetail.username }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">下单时间:</span>
            <span>{{ taskDetail.created_time }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childCon">
            <span class="childMt">联系电话:</span>
            <span>{{ taskDetail.telephone }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">订单来源:</span>
            <span
              v-if="
                taskDetail.channelTitle == null || taskDetail.channelTitle == ''
              "
              >{{ taskDetail.channel }}</span
            >
            <span v-else
              >{{ taskDetail.channel }}-{{ taskDetail.channelTitle }}</span
            >
          </div>
          <div class="childCon">
            <span class="childMt">房屋问题:</span>
            <span>{{ taskDetail.problemTitle }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">添加微信:</span>
            <span v-if="taskDetail.wechat == 1">是</span>
            <span v-else>否</span>
          </div>
          <div class="childCon2">
            <span class="childMt">维修地址:</span>
            <span>{{ taskDetail.addres }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childCon3">
            <span class="childMt">备注信息:</span>
            <span>{{ taskDetail.remarks }}</span>
          </div>
        </div>
      </div>

      <!-- 用户打标签 -->
      <div
        style="
          border: 1px solid #ccc;
          width: 100%;
          padding: 10px;
          margin-top: 20px;
        "
      >
        <div>用户打标</div>
        <el-form :model="form" label-width="90px">
          <div style="display: flex">
            <div style="display: flex; flex-wrap: wrap">
              <el-form-item
                v-for="(item, index) in arr"
                :key="index"
                :label="item.title"
                style="width: 33%"
              >
                <div style="display: flex">
                  <el-checkbox-group
                    v-model="form[item.title]"
                    size="mini"
                    v-if="item.data[0].option === 2"
                    style="flex: 10"
                  >
                    <el-checkbox-button
                      v-for="ra in item.data"
                      :label="ra"
                      :key="ra.contacts_id"
                      >{{ ra.content }}</el-checkbox-button
                    >
                  </el-checkbox-group>
                  <el-radio-group
                    v-model="form[item.title]"
                    size="mini"
                    style="flex: 10"
                    v-else
                    @change="checkRadio"
                  >
                    <el-radio-button
                      v-for="ra in item.data"
                      :label="ra"
                      :key="ra.contacts_id"
                      >{{ ra.content }}</el-radio-button
                    >
                  </el-radio-group>
                  <el-image
                    v-if="
                      form[item.title].length !== 0 && item.data[0].option === 2
                    "
                    src="https://imgaes.yiniaoweb.com/cc/finish.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin: 10px 0 0 2px;
                      flex: 1;
                    "
                    fit="scale-down"
                  ></el-image>
                  <el-image
                    v-if="
                      form[item.title].length === 0 && item.data[0].option === 2
                    "
                    src="https://imgaes.yiniaoweb.com/cc/nofinish.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin: 10px 0 0 2px;
                      flex: 1;
                    "
                    fit="scale-down"
                  ></el-image>
                  <el-image
                    v-if="form[item.title] !== '' && item.data[0].option === 1"
                    src="https://imgaes.yiniaoweb.com/cc/finish.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin: 10px 0 0 2px;
                      flex: 1;
                    "
                    fit="scale-down"
                  ></el-image>
                  <el-image
                    v-if="form[item.title] === '' && item.data[0].option === 1"
                    src="https://imgaes.yiniaoweb.com/cc/nofinish.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin: 10px 0 0 2px;
                      flex: 1;
                    "
                    fit="scale-down"
                  ></el-image>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitLabel" size="small"
                  >打标</el-button
                >
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <!-- 完工回访 -->
      <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width: 100%;
        "
      >
        <div style="font-size: 20px; margin: 8px 0 20px 0">完工回访</div>
        <div class="prCon">
          <div class="childCon7">
            <span class="childMt">下单时间:</span>
            <span>{{ taskDetail.created_time }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">派单时间:</span>
            <span>{{ taskDetail.time }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">打卡时间:</span>
            <span>{{ taskDetail1.clock_in }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">报价时间:</span>
            <span>{{ taskDetail1.crtime }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">报价金额:</span>
            <span>{{ taskDetail1.totalprice }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childCon7">
            <span class="childMt">开工时间:</span>
            <span>{{ taskDetail1.start_time }}</span>
          </div>
          <div class="childCon7">
            <span class="childMt">预计工期:</span>
            <span>{{ taskDetail1.gong }}天</span>
          </div>
          <div class="childCon7">
            <span class="childMt">完工时间:</span>
            <span>{{ taskDetail1.finish_time }}</span>
          </div>
        </div>
      </div>
      <!-- 处理记录 -->
      <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width: 100%;
          margin-bottom: 10px;
        "
      >
        <div style="font-size: 20px; margin-top: 8px">处理记录</div>
        <avue-crud :data="dealRecord" :option="option2">
          <template slot-scope="scope" slot="score">
            <span v-if="scope.row.score == 1">一星级</span>
            <span v-if="scope.row.score == 2">二星级</span>
            <span v-if="scope.row.score == 3">三星级</span>
            <span v-if="scope.row.score == 4">四星级</span>
            <span v-if="scope.row.score == 5">五星级</span>
          </template>
          <template slot-scope="scope" slot="score2">
            <span v-if="scope.row.score2 == 1">一星级</span>
            <span v-if="scope.row.score2 == 2">二星级</span>
            <span v-if="scope.row.score2 == 3">三星级</span>
            <span v-if="scope.row.score2 == 4">四星级</span>
            <span v-if="scope.row.score2 == 5">五星级</span>
          </template>
          <template slot-scope="scope" slot="score3">
            <span v-if="scope.row.score3 == 1">一星级</span>
            <span v-if="scope.row.score3 == 2">二星级</span>
            <span v-if="scope.row.score3 == 3">三星级</span>
            <span v-if="scope.row.score3 == 4">四星级</span>
            <span v-if="scope.row.score3 == 5">五星级</span>
          </template>
        </avue-crud>
      </div>
      <!-- 沟通记录表格 -->
      <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width: 100%;
          margin-bottom: -80px;
        "
      >
        <div style="font-size: 20px; margin-top: 8px">沟通记录</div>
        <avue-crud :data="connectRecord" :option="option1">
          <template slot-scope="scope" slot="conversation">
            <span v-if="scope.row.conversation == 0">是</span>
            <span v-if="scope.row.conversation == 2">否</span>
          </template>
          <template slot-scope="scope" slot="record">
            <div v-if="scope.row.record.length == 0">暂无录音</div>
            <div v-else>
              <audio
                style="width: 100%; outline: none"
                :src="scope.row.record[0].monitorfilename"
                controls="controls"
              ></audio>
            </div>
          </template>
          <template slot="log" slot-scope="scope">
            <span v-if="scope.row.log.length == 0">暂无图片</span>
            <div v-else>
              <img
                v-for="(item, index) in scope.row.log"
                :src="item.value"
                :key="index"
                style="width: 50px; height: 50px; margin-right: 2px"
              />
            </div>
          </template>
        </avue-crud>
      </div>
    </div>
    <!-- 底部操作按钮 -->
    <div
      style="
        background: #dae1e6;
        display: flex;
        padding: 2% 0 2% 7%;
        margin: 2% 0 0 -3% !important;
        position: fixed;
        width: 80%;
        left: 274px;
        bottom: 0;
        z-index: 99;
      "
    >
      <div
        style="
          padding-right: 5%;
          margin-right: 5%;
          border-right: 1px solid white;
        "
      >
        <el-button type="primary" size="medium" plain @click="openFinish"
          >任务完成</el-button
        >
        <!-- <el-button  type="primary" @click="recoveryOrder()">恢复订单</el-button> -->
        <el-button type="warning" size="medium" plain @click="finishSetting"
          >取消挂起</el-button
        >
        <el-button
          @click="addWechat"
          style="color: red"
          size="medium"
          v-if="taskDetail.wechat == 1"
          >加微信标记(已添加)</el-button
        >
        <el-button type="primary" size="medium" plain @click="addWechat" v-else
          >加微信标记(未添加)</el-button
        >
      </div>
      <div>
        <el-button
          v-if="wiahulema2"
          type="success"
          size="medium"
          plain
          @click="waihu('dian')"
          >外呼{{ waihudian.username }}店长</el-button
        >
        <el-button v-else-if="!wiahulema2" type="danger" size="medium" plain
          >呼叫中</el-button
        >
        <el-button
          v-if="wiahulema"
          type="warning"
          size="medium"
          plain
          @click="waihu"
          >外呼客户</el-button
        >
        <el-button v-else-if="!wiahulema" type="danger" size="medium" plain
          >呼叫中</el-button
        >
        <el-button type="primary" size="medium" plain @click="lookOrigin"
          >查看原始订单</el-button
        >
        <el-button type="primary" size="medium" plain @click="openDeliver"
          >任务转派</el-button
        >
      </div>
    </div>
    <!-- 超时未接单任务完成模态框 -->
    <el-dialog
      title="任务完成"
      :visible.sync="finishDialog1"
      width="45%"
      z-index="100"
      center
      top="15%"
    >
      <div style="display: flex; padding: 0 5% 5% 0">
        <el-form :model="form">
          <el-form-item :label-width="formLabelWidth2" label="回访方式">
            <el-checkbox-group v-model="rateForm.mode">
              <el-checkbox v-for="item in modess" :label="item" :key="item">{{
                item
              }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="施工评价"
            :label-width="formLabelWidth2"
            class="formItemMb"
          >
            <el-rate
              v-model="rateForm.value1"
              @change="rateChange1"
              style="margin-top: 10px"
            ></el-rate>
          </el-form-item>
          <el-form-item
            label="原因"
            :label-width="formLabelWidth2"
            class="formItemMb"
          >
            <el-checkbox-group
              v-model="rateForm.checkList"
              @change="checkChange"
            >
              <template v-if="rateForm.value1 == 1">
                <el-checkbox
                  v-for="v in checkBox1"
                  :key="v"
                  :label="v"
                  class="checkStyle"
                ></el-checkbox>
              </template>
              <template v-if="rateForm.value1 == 2">
                <el-checkbox
                  v-for="v in checkBox2"
                  :key="v"
                  :label="v"
                  class="checkStyle"
                ></el-checkbox>
              </template>
              <template v-if="rateForm.value1 == 3">
                <el-checkbox
                  v-for="v in checkBox3"
                  :key="v"
                  :label="v"
                  class="checkStyle"
                ></el-checkbox>
              </template>
              <template v-if="rateForm.value1 == 4">
                <el-checkbox
                  v-for="v in checkBox4"
                  :key="v"
                  :label="v"
                  class="checkStyle"
                ></el-checkbox>
              </template>
              <template v-if="rateForm.value1 == 5">
                <el-checkbox
                  v-for="v in checkBox5"
                  :key="v"
                  :label="v"
                  class="checkStyle"
                ></el-checkbox>
              </template>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="店长评价"
            :label-width="formLabelWidth2"
            class="formItemMb"
          >
            <el-rate
              v-model="rateForm.value2"
              @change="rateChange2"
              style="margin-top: 10px"
            ></el-rate>
          </el-form-item>
          <el-form-item
            label="师傅评价"
            :label-width="formLabelWidth2"
            class="formItemMb"
          >
            <el-rate
              v-model="rateForm.value3"
              @change="rateChange3"
              style="margin-top: 10px"
            ></el-rate>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth2">
            <el-input
              type="textarea"
              placeholder="请输入备注内容"
              v-model="rateForm.textarea"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth2" label="回访图片">
            <el-upload
              class="avatar-uploader"
              drag
              action="https://public.yiniao.co/api/v1/upload"
              list-type="picture-card"
              :multiple="completionUpload.mutrue"
              :on-preview="handlePicture"
              :on-success="upsus"
              :on-remove="remove"
              :file-list="completionUpload.fileList"
              :data="completionUpload.tuzhi"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="finishDialog1 = false"
          >取 消</el-button
        >
        <el-button type="primary" size="medium" plain @click="finishTask"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 超时未接单任务挂起/设置跟进模态框 -->
    <el-dialog
      title="任务挂起/设置跟进"
      :visible.sync="finishDialog2"
      width="45%"
      z-index="100"
      center
      top="15%"
    >
      <div style="display: flex; padding: 0 13% 5% 18%">
        <el-form :model="form1">
          <div
            class="blocks2 flex_items flex_start"
            style="margin-bottom: 20px; margin-right: 1%; padding-left: 3px"
          >
            <span style="width: 85px; margin-right: 15px">下次跟进时间</span>
            <el-date-picker
              :clearable="true"
              v-model="form1.orderTime"
              type="datetime"
              placeholder="选择下次跟进时间"
              style="width: 300px"
            ></el-date-picker>
          </div>
          <el-form-item
            label="跟进原因"
            :label-width="formLabelWidth"
            prop="reasonArr"
          >
            <el-cascader
              change-on-select
              :props="defaultParams"
              v-model="form1.reasonArr"
              :options="options"
              filterable
              clearable
              class="casStyle"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="跟进备注" :label-width="formLabelWidth">
            <el-input
              v-model="form1.remark"
              type="textarea"
              placeholder="请输入备注内容"
              autocomplete="off"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="沟通方式" :label-width="formLabelWidth">
            <el-select
              v-model="form1.mode"
              placeholder="请选择沟通方式"
              style="width: 300px"
            >
              <el-option
                v-for="item in modes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="finishDialog2 = false"
          >取 消</el-button
        >
        <el-button type="warning" size="medium" plain @click="finishSetting"
          >取消挂起</el-button
        >
      </span>
    </el-dialog>
    <!-- 超时未接单任务转派模态框 -->
    <el-dialog
      title="任务转派"
      :visible.sync="finishDialog3"
      width="45%"
      z-index="100"
      center
      top="10%"
    >
      <div style="display: flex; padding: 0 13% 5% 18%">
        <el-form :model="form2">
          <el-form-item label="转派客服" :label-width="formLabelWidth">
            <el-select
              v-model="form2.customer"
              placeholder="请选择转派客服"
              style="width: 300px"
              :clearable="true"
            >
              <el-option
                v-for="(item, index) in clients"
                :key="index"
                :label="item.username"
                :value="item.admin_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="finishDialog3 = false"
          >取 消</el-button
        >
        <el-button type="primary" size="medium" plain @click="finishDeliver"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 恢复订单模态框 -->
    <el-dialog
      title="订单恢复"
      :visible.sync="finishDialog4"
      width="45%"
      center
      top="10%"
    >
      <div style="text-align: center; font-size: 16px; margin: 30px 0">
        订单恢复至取消前状态
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="finishDialog4 = false"
          >取 消</el-button
        >
        <el-button type="primary" size="medium" plain @click="finishRecovery"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看原始订单 -->
    <el-dialog
      :visible.sync="dialogTableVisibleLook"
      width="70%"
      :close-on-click-modal="false"
    >
      <Detailes2></Detailes2>
    </el-dialog>
  </div>
</template>
<script>
import config from "@/util/config";
export default {
  props: ["parentRow"],
  data() {
    return {
      //任务详细信息
      taskDetail: {
        contacts: "张三",
        state: "一个状态",
        order_id: 202107141422,
        store_name: "金沙店",
        username: "李华",
        created_time: "1994-02-23 00:00:00",
        telephone: 15182996821,
        channel: "推客宝盒",
        problemTitle: "墙面翻新",
        wechat: 1,
        addres: "四川省成都市金牛区万通金融广场西塔",
        remarks: "这是一个备注呢，多写几个字",
      },
      //完工回访
      taskDetail1: {},
      //完工回访
      completionUpload: {
        // 图片上传
        mutrue: true,
        dialogImageUrl: "",
        tuzhi: { app_name: "public" },
        imgs: [],
        fileList: [],
      },
      modess: ["微信", "电话", "短信", "施工群", "其他"], //回访方式
      connectRecord: [],
      dealRecord: [],
      option1: {
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列动态显隐按钮
        refreshBtn: false, // 刷新按钮
        column: [
          // {
          //   label: "时间",
          //   prop: "th_time",
          //   type: "date",
          //   format: "yyyy-MM-dd hh:mm:ss",
          //   valueFormat: "yyyy-MM-dd hh:mm:ss",
          // },
          {
            label: "处理人",
            prop: "username",
          },
          {
            label: "方式",
            prop: "mode",
          },
          {
            label: "是否接通",
            prop: "conversation",
            slot: true,
          },
          {
            label: "沟通内容",
            prop: "remar",
          },
          {
            label: "沟通录音",
            prop: "record",
            slot: true,
          },
          {
            label: "跟进时间",
            prop: "th_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "下次跟进",
            prop: "end_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "图片",
            prop: "log",
            type: "upload",
            imgFullscreen: true,
            imgType: "card",
            width: "200px",
            listType: "picture-card",
          },
        ],
      },
      option2: {
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列动态显隐按钮
        refreshBtn: false, // 刷新按钮
        column: [
          {
            label: "时间",
            prop: "uptime",
            width: "200px",
          },
          {
            label: "施工评价星级",
            prop: "score",
            slot: true,
          },
          {
            label: "店长评价星级",
            prop: "score2",
            slot: true,
          },
          {
            label: "师傅评价星级",
            prop: "score3",
            slot: true,
          },
          {
            label: "评价",
            prop: "content",
          },
        ],
      },
      finishDialog1: false,
      radio1: 2,
      shopper: "",
      shoppers: [],
      textarea1: "",
      finishDialog2: false,
      orderTime: "",
      form1: {
        reasonArr: [],
        orderTime: "",
        mode: "",
        reason: "",
        remark: "",
      },
      formLabelWidth: "100px",
      form2: {
        customer: "",
      },
      finishDialog3: false,
      finishDialog4: false,
      options: [],
      defaultParams: {
        label: "title",
        value: "title",
        children: "children",
      },
      rateForm: {
        value1: 5,
        checkList: [],
        value2: 5,
        value3: 5,
        textarea: "",
        mode: [],
      },
      formLabelWidth2: "130px",
      clients: [],
      dialogTableVisibleLook: false,
      waihudian: { username: "", mobile: "" },
      wiahulema: true,
      wiahulema2: true,
      waihulun: 0,
      isSaveState: true,
      ExternalData: "",
      call: [],
      //  沟通方式
      modes: [
        {
          value: "电话",
          label: "电话",
        },
        {
          value: "微信",
          label: "微信",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      checkBox1: config.checkBox1,
      checkBox2: config.checkBox2,
      checkBox3: config.checkBox3,
      checkBox4: config.checkBox4,
      checkBox5: config.checkBox5,
      form: {},
      arr: [],
    };
  },
  mounted() {
    this.getclient();
    this.getProductType();
    this.getDetail();
  },
  methods: {
    //图片上传
    uploadFile(file) {
      this.formDate.append(file, file.file);
    },
    handlePicture(file) {
      this.completionUpload.dialogImageUrl = file.url;
    },
    upsus(response, file, fileList) {
      this.completionUpload.imgs.push(response.data.file.path);
    },
    remove(file, fileList) {
      for (var i = 0; i < this.completionUpload.imgs.length; i++) {
        if (this.completionUpload.imgs[i] == file.response.data.file.path) {
          this.completionUpload.imgs.splice(i);
        }
      }
    },
    checkRadio() {},
    submitLabel() {},
    // 获取客服
    getclient() {
      this.util.get(this.HOST + "/Common/CustomerService", {}).then((res) => {
        if (res.code == 200) {
          this.clients = res.data;
        }
      });
    },
    getDetail() {
      //获取任务详情
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Order/TaskInfo", {
          cc_id: this.parentRow.cc_id,
          order_id: this.parentRow.order_id,
          type: this.parentRow.type,
        })
        .then((res) => {
          if (res.code == 200) {
            this.taskDetail = res.data;
            this.taskDetail1 = res.data.data;
            this.connectRecord = res.data.through;
            this.dealRecord = res.data.result;
            this.arr = res.data.contactLabelList;
            this.arr.forEach((v) => {
              if (v.data[0].option == 1) {
                const choiceData = v.data.find((item) => item.choice == 1);
                this.$set(this.form, v.title, choiceData || "");
              } else {
                this.$set(
                  this.form,
                  v.title,
                  v.data.filter((item) => item.choice == 1)
                );
              }
              v.data.forEach((item) => {
                item.isMyChoice = 0;
              });
            });
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
      this.managerPhone();
    },
    // 获取店长号码名字
    managerPhone() {
      this.util
        .get(this.HOST + "/User/findShopOwner", {
          order_id: this.$store.state.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.waihudian = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取级联选择器数据
    getProductType() {
      // 这是从后台获取数据
      this.util.get(this.HOST + "/Common/getRemote").then((res) => {
        if (res.code == 200) {
          // 调用递归方法，去除级联数据后将数据赋值给级联选择器
          this.options = this.getTreeData(res.data);
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    }, // 递归方法
    getTreeData(data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续递归调用本方法
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
    lookOrigin() {
      this.dialogTableVisibleLook = true;
    },
    addWechat() {
      this.$loading(this.util.opload);
      let wechat = "";
      if (this.taskDetail.wechat == 1) {
        wechat = 0;
      } else {
        wechat = 1;
      }
      this.util
        .get(this.HOST + "/Order/SelectorSwitch", {
          order_id: this.parentRow.order_id,
          wechat: wechat,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "处理成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.getDetail();
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
      this.getDetail();
    },
    finishTask() {
      // return;
      //任务完成处理模态框确认按钮
      if (this.rateForm.mode.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择回访方式!",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.rateForm.checkList.length == 0) {
        this.$message({
          showClose: true,
          message: "请施工评价星级原因!",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      // if ((this.rateForm.textarea = "")) {
      //   this.$message({
      //     showClose: true,
      //     message: "请输入备注内容!",
      //     type: "error",
      //     duration: 1200,
      //     offset: 90,
      //   });
      //   return false;
      // }
      if (!this.rateForm.textarea) {
        this.$message({
          showClose: true,
          message: "请输入备注内容!",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.rateForm.checkList.push(this.rateForm.textarea);
      let ideas = JSON.parse(JSON.stringify(this.rateForm.checkList)).join(",");
      // //console.log(ideas, "ideas+++++");
      // return;
      this.util
        .post(this.HOST + "/Order/ProcessingTasks", {
          mode: JSON.parse(JSON.stringify(this.rateForm.mode)).join(","),
          cc_id: this.parentRow.cc_id,
          order_id: this.parentRow.order_id,
          type: this.parentRow.type,
          score: this.rateForm.value1,
          score2: this.rateForm.value2,
          score3: this.rateForm.value3,
          content: ideas,
          tupian: JSON.parse(JSON.stringify(this.completionUpload.imgs)),
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "处理成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.finishDialog1 = false;
            this.$parent.returnTaskList();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
            this.finishDialog1 = false;
          }
        });
    },
    finishCancel() {},
    finishSetting() {
      // let time = this.util.datatime(this.form1.orderTime);
      //   if (this.form1.orderTime == null || time == "NaN-NaN-NaN NaN:NaN:NaN") {
      //   this.$message({
      //     showClose: true,
      //     type: "error",
      //     duration: 1200,
      //     message: "请选择跟进时间",
      //     offset:90
      //   });
      //   return false;
      // }
      // if (this.form1.reasonArr.length == 0) {
      //   this.$message({
      //     showClose: true,
      //     message: "请选择跟进原因!",
      //     type: "error",
      //     duration: 1200,
      //     offset: 300,
      //   });
      //   return false;
      // }
      // if (this.form1.remark == "") {
      //   this.$message({
      //     showClose: true,
      //     message: "请输入备注!",
      //     type: "error",
      //     duration: 1200,
      //     offset: 300,
      //   });
      //   return false;
      // }
      // if (this.form1.mode == "") {
      //   this.$message({
      //     showClose: true,
      //     message: "请选择沟通方式!",
      //     type: "error",
      //     duration: 1200,
      //     offset: 300,
      //   });
      //   return false;
      // }
      let hang_up = 0;
      if (this.parentRow.hang_up == 0) {
        hang_up = 1;
      } else if (this.parentRow.hang_up == 1) {
        hang_up = 0;
      }
      //设置挂起与跟进
      this.util
        .get(this.HOST + "/Cc/HangUp", {
          cc_id: this.parentRow.cc_id,
          order_id: this.parentRow.order_id,
          // end_time: time,
          // remar: this.form1.remark,
          // mode: this.form1.mode,
          // hang_up:this.parentRow.hang_up,
          hang_up: hang_up,
          // status: this.form1.reasonArr[0],
          // status1: this.form1.reasonArr[1],
          // status2: this.form1.reasonArr[2],
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "处理成功!",
              type: "success",
              duration: 1200,
              offset: 300,
            });
            this.finishDialog2 = false;
            this.$parent.returnTaskList();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 300,
            });
            this.finishDialog2 = false;
          }
        });
    },
    finishRecovery() {
      this.util
        .get(this.HOST + "/Order/recovery", {
          order_id: this.parentRow.cc_id,
          state: this.parentRow.status,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "恢复成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
      this.finishDialog4 = false;
    },
    // 发起外呼
    waihu(aa) {
      if (!this.wiahulema || !this.wiahulema2) {
        this.$message({
          showClose: true,
          message: "不能同时呼叫!",
          type: "warning",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      let phone = "";
      if (aa == "dian") {
        if (this.waihudian.username == "") {
          this.$message({
            showClose: true,
            message: "该订单暂未指派店长!",
            type: "warning",
            duration: 1200,
            offset: 90,
          });
          return false;
        } else {
          phone = this.waihudian.mobile;
        }
      } else {
        phone = this.waihudian.telephone;
      }
      // 打包后
      this.$jsonp(this.HOST4, {
        account: "N000000015324",
        appid: "ncvsorfw58bk2o6r",
        secret: "31792f00-d2e5-11e9-8be9-b3731625d6b2",
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
          this.kaihu(res.accessToken, phone, aa);
        }
      });
    },
    kaihu(token, phone, aa) {
      let timestamp = new Date().getTime();
      let good = "customer";
      if (aa == "dian") {
        good = "shopowner";
      }
      let kk = timestamp + "-" + good;
      this.lunxun(kk);
      // 打包后
      this.$jsonp(this.HOST5, {
        phonenum: phone,
        integratedid: sessionStorage.getItem("integratedid"),
        accessToken: token,
        account: "N000000015324",
        flag: 106,
        ExternalData: "main:" + kk,
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
        }
      });
    },
    lunxun(kk) {
      this.waihulun = 1;
      let that = this;
      let arr = [];
      that.iCount = window.setInterval(function () {
        that.$http
          .get(that.HOST + "/Callback/jie", {
            params: {
              ExternalData: kk,
            },
          })
          .then((res) => {
            if (res.data.code == 200) {
              that.waihulun = 0;
              that.wiahulema = true;
              that.wiahulema2 = true;
              that.isSaveState = true;
              that.call.push(res.data.data.id);
              clearInterval(that.iCount);
            } else {
              arr.push(res.data.code);
              if (arr.length == 35) {
                that.waihulun = 0;
                that.wiahulema = true;
                that.wiahulema2 = true;
                that.isSaveState = true;
                arr = [];
                clearInterval(that.iCount);
              }
            }
          })
          .catch(function (error) {
            // 请求失败处理
            window.console.log(error);
          });
      }, 2000);
    },
    openFinish() {
      //console.log("11232432++++++");
      this.finishDialog1 = true;
    },
    openSetting() {
      this.finishDialog2 = true;
    },
    openDeliver() {
      this.finishDialog3 = true;
    },
    recoveryOrder() {
      //恢复订单
      this.finishDialog4 = true;
    },
    handleChange() {
      //跟进原因选择改变
    },
    rateChange1() {
      this.$set(this.rateForm, "checkList", []);
    },
    rateChange2() {},
    rateChange3() {},
    finishDeliver() {
      if (this.form2.customer == "") {
        this.$message({
          showClose: true,
          message: "未选择转派人!",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.util
        .get(this.HOST + "/Cc/Task", {
          cc_id: this.parentRow.cc_id,
          admin_id: this.form2.customer,
          order_id: this.parentRow.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "转派成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.$parent.returnTaskList();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
      this.finishDialog3 = false;
    },
    checkChange() {
      //console.log(this.rateForm.checkList, "123++");
    },
  },

  components: {
    Detailes2: () => import("@/components/detailes2.vue"),
  },
};
</script>
<style scoped>
.totalPr {
  background: white !important;
  padding-left: 25px;
  width: 96% !important;
}
.container {
  margin: 0 auto;
  background: white;
  padding: 40px 0 0 80px;
  border-radius: 8px;
  /* height: 100%; */
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.abs {
  position: absolute;
}
.wuyekuang {
  top: 37px;
  left: 76px;
  width: 202px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
  color: #666;
}
.zanwu {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 15px;
  top: 50px;
  left: 76px;
  width: 202px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
.box {
  padding: 30px 0 0 30px;
  background: white;
}
.prCon {
  display: flex;
  flex-wrap: wrap;
}
.childCon {
  width: 16%;
  margin-bottom: 40px;
}
.childCon2 {
  width: 32%;
  margin-bottom: 40px;
}
.childCon3 {
  width: 100%;
  margin-bottom: 20px;
}
.childCon4 {
  width: 24%;
  margin-bottom: 60px;
}
.childCon5 {
  width: 48%;
  margin-bottom: 60px;
}
.childCon6 {
  width: 24%;
  margin-bottom: 20px;
}
.childCon7 {
  width: 20%;
  margin-bottom: 20px;
}
.childCon8 {
  width: 60%;
  margin-bottom: 20px;
}
.childMt {
  margin-right: 8px;
}
.btnStyle {
  border: 1px solid #409eff;
  color: #409eff;
}
.avue-crud__menu {
  min-height: 4px !important;
}
.casStyle {
  width: 300px !important;
}
/* .checkStyle {
  width: 18%;
}
.checkStyle1 {
  width: 30%;
} */
.formItemMb {
  margin-bottom: 10px;
}
</style>
